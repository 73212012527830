/////////// banner start ///////////
.finance-banner{
    height: 85vh;
    @media (max-width: 767.98px) {
        height: auto;
    }
    h2{
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: -0.6px;
        @media (max-width: 767.98px) {
            font-size: 32px;
        }
        span{
            background: linear-gradient(#EA3162, #EA3162);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    p{
    color: var(--Neutral-04, #787F84);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 177.778% */
    letter-spacing: -0.18px;
        @media (max-width: 767.98px) {
            font-size: 16px;
        }
    }

    button{
        display: flex;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        @media (max-width: 767.98px) {
            margin-bottom: 0.5rem;
        }
    }

    .content-wapper{
        display: flex;
        padding-left: 4.5rem;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        background-color: #F5F6F7;
        @media (max-width: 767.98px) {
                padding-left: 0.5rem;
                padding-top: 2rem;
            }
    }

    .image-wapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background: linear-gradient(#f7e7e9, #f7e7e9);
        @media (max-width: 767.98px) {
                img{
                    width: 100%;
                    height: 100%;
                }
            }
    }

}

////////// Banner end ////////////
 

//////////// start Credit Market Place //////////////

.credit-market-place{
    display: flex;
    padding:  2rem 0rem;
    flex-direction: column;
    gap: 50px;

    .content-credit-market-wapper{
        margin-right: 5rem;
        border-radius: 0rem 5rem 5rem 0rem;
        background-color: #dcebf5;
        .img-content{
            padding: 0rem 7rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .content-wapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 1rem;
            h1{
                font-size: 3.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                letter-spacing: 0.5px;
            }
            p{
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 300;
                line-height: 150%;
            }
            .order-now{
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -.2px;
                margin-bottom: 0;
            }
        }
    @media (max-width: 767.98px), (max-width: 991.98px) {
        margin-right: 0rem;
        margin-bottom: 2rem;
        border-radius: 0rem;
        text-align: center;
        .img-content {
            padding: 0rem;
        }
        .content-wapper {
            align-items: center;
            margin-bottom: 1rem;
            h1 {
            font-size: 32px;
            }

            p {
                font-size: 14px;
            }

            .order-now {
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -.2px;
                margin-bottom: 0;
            }
        }
    }
    }
}
       

//////////// end Credit Market Place //////////////
 
/////////// Financial Partners start //////// 

.financial-wapper {
    display: flex;
    padding: 2rem 0rem;
    flex-direction: column;
}

.financial-container{
  display: flex;
    gap: 2.5rem;
    --bs-gutter-x: 3rem;
    padding: 83px 0px 0rem;
    @media (max-width: 767.98px) {
    --bs-gutter-x: 0rem;
        padding: 40px 0.5rem;
        gap:1.3rem;
    }
}

.financial-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 24px;
    div:nth-child(2) {
        margin-top: 1.3rem;
    }
}

.financial-partners-content{
    display: flex;
    padding: 32px;
    flex-direction: column;
    gap: 12px;
    // border-radius: 24px;
    // border: 1px solid rgba(29, 38, 58, 0.30);
    @media (max-width: 767.98px) {
    padding: 24px;
    }    
}

.financial-partners-card-wapper{
    display: flex;
    align-self: center;
    width: 100%;
    align-items: flex-end;
    justify-content: space-around;
}

.financial-partners-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgba(33, 35, 38, 0.10);
    box-shadow: 0px 4px 100px 0px rgba(32, 34, 37, 0.07);
    background: #fff;
}

.lending-partners{
    display: flex;
    padding: 32px;
    border-radius: 24px;
    // border: 1px solid rgba(29, 38, 58, 0.30);
    height: 100%;
    @media (max-width: 767.98px) {
    padding: 40px 0.5rem;
    }
}


.lending-partners-card{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgba(33, 35, 38, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 100px 0px rgba(32, 34, 37, 0.07);
    @media (max-width: 767.98px) {
        img{
            width: 100%; 
            height: 100%;
        }
    }
}

/////////// Financial Partners end ////////