$unit : rem;

$BASE : 0.25;
$SPACING_0B : 0 + $unit;
$SPACING_1 : $BASE / 4 + $unit;
$SPACING_2 : $BASE / 2 + $unit;
$SPACING_6 : ($BASE * 3) / 2 + $unit;
$SPACING_10 : ($BASE * 5) / 2 + $unit;
$SPACING_1B : $BASE + $unit;
$SPACING_2B : 2 * $BASE + $unit;
$SPACING_3B : 3 * $BASE + $unit;
$SPACING_4B : 4 * $BASE + $unit;
$SPACING_5B : 5 * $BASE + $unit;
$SPACING_6B : 6 * $BASE + $unit;
$SPACING_7B : 7 * $BASE + $unit;
$SPACING_8B : 8 * $BASE + $unit;
$SPACING_9B : 9 * $BASE + $unit;
$SPACING_10B : 10 * $BASE + $unit;
$SPACING_11B : 11 * $BASE + $unit;
$SPACING_12B : 12 * $BASE + $unit;
$SPACING_13B : 13 * $BASE + $unit;
$SPACING_14B : 14 * $BASE + $unit;
$SPACING_15B : 15 * $BASE + $unit;
$SPACING_16B : 16 * $BASE + $unit;
$SPACING_17B : 17 * $BASE + $unit;
$SPACING_18B : 18 * $BASE + $unit;
$SPACING_20B : 20 * $BASE + $unit;
$SPACING_21B : 21 * $BASE + $unit;
$SPACING_22B : 22 * $BASE + $unit;
$SPACING_24B : 24 * $BASE + $unit;
$SPACING_26B : 26 * $BASE + $unit;
$SPACING_28B : 28 * $BASE + $unit;
$SPACING_32B : 32 * $BASE + $unit;
