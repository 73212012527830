footer {
  background: $background-black1;
  color: $text-white;
  padding: $SPACING_16B $SPACING_28B $SPACING_6B;
  @media (max-width: 767.98px), (max-width: 991.98px) {
    font-size: 12px;
    padding: 27px 20px;
  }

  .address{
    @media (max-width: 767.98px), (max-width: 991.98px) {
      align-items: center;
    }

    .footer-addr-contact {
      > a {
        align-items: center;
        font-size: 13px;
        
        p {
          flex: 1;
        }
      }

      svg {
        width: 40px;
      }
      h6{
        font-size: 13px;
      }
            div{
              a{
                font-size: 13px;
              }
            }
    }
  }
  
  .link-list{
    @media (max-width: 767.98px), (max-width: 991.98px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0.5rem;
    @media (max-width: 767.98px), (max-width: 991.98px) {
      padding-left: 0rem;
    }
    li {
      text-decoration: none;
      color: $text-white;
      /* Body/Sub Heading 16/Medium */
      font-style: normal;
      font-weight: $BOLD_500;
      line-height: $LINE_HEIGHT_MAX; /* 162.5% */
      @media (max-width: 767.98px), (max-width: 991.98px) {
        font-size: 12px;
      }
    }
  }
}

.join-metalbook-wapper{
  position: relative;
  padding : 0rem 4rem;
  @media (max-width: 767.98px),
    (max-width:991.98px) {
      padding: 0rem;
    }
}

.join-metalbook {
  border-radius: $SPACING_6B;
  background: linear-gradient(
    104deg,
    $background-greenlight3 -4.53%,
    $background-greenlight2 111.52%
  );
  display: inline-flex;
  padding: $SPACING_14B $SPACING_10B;
  flex-direction: column;
  gap: $SPACING_10;
  margin-bottom: -5px;
  top: -280px;
  position: absolute;
  align-items: center;
  
  img{
    width: 50px;
    height: 50px;
  }

  @media (max-width: 767.98px), (max-width: 991.98px) {
      display: flex;
      position: static;
      padding: 1rem;
      margin: 1rem 1rem 0rem;
      a {
          font-size: 12px;
      }
    }

  .text-1 {
    color: $text-white;
    font-size: $XXX_LARGE;
    font-weight: $BOLD_600;
    line-height: $LINE_HEIGHT_MIN; /* 120% */
    letter-spacing: -0.6px;
    @media (max-width: 767.98px), (max-width: 991.98px) {
      font-size: $XX_LARGE;
    }
  }

  .text-2 {
    color: $text-white;
    font-size: $LARGE;
    letter-spacing: 0.16px;
    opacity: 0.8;
    font-weight: 400;
    line-height: 160%;
    @media (max-width: 767.98px), (max-width: 991.98px) {
      line-height: normal;
      font-size: $SMALL;
    }
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  margin-block: $SPACING_10B;
}

.footer-link{
      display: flex;
      justify-content: space-between;
      @media (max-width: 767.98px), (max-width: 991.98px) {
        justify-content: space-evenly;
      }
}

.footer-links {
  li {
    margin: 1rem 0px;

    @media (max-width: 767.98px), (max-width: 991.98px) {
      margin: 0.4rem 0px;
      // flex-direction: column-reverse;
    }
  }

}
