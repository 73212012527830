.jobs-container {
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 88px 140px;
  @media (max-width: 767.98px) {
    padding: 48px 24px;
    gap: 40px;
  }
  .paragraph {
    font-size: 16px;
    line-height: 155%;
  }
}

.jobs-button {
  display: flex;
  column-gap: 4px;
  width: fit-content;
  padding: 4px 14px 4px 12px;
  justify-content: left;
  align-items: center;
  border: 1px solid #dfe1e7;
  border-radius: 5px 5px 5px 5px;
}

.careers-heading {
  gap: 16px;
  .paragraph {
    max-width: 601px;
    font-size: 16px;
  }
}

.jobs-heading {
  font-size: 48px;
  line-height: 125%;
  color: #0d0d12;
  @media (max-width: 767.98px) {
    font-size: 32px;
  }
}

.job-item {
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 12px;
  border-top: 1px solid #dfe1e7;
  border-bottom: 1px solid #dfe1e7;
  border-collapse: collapse;
  @media (max-width: 767.98px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  div:first-child {
    font-size: 24px;
    font-weight: 600;
  }
  div:nth-child(2) {
    column-gap: 8px;
  }
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #dfe1e7;
  border-radius: 50%;
  padding-left: 1px;
  padding-right: 1px;
}

.paragraph-red {
  padding-left: 4px;
  background: -webkit-linear-gradient(
    0deg,
    $background-yellow -76.29%,
    $background-red 89.33%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// css for job description

.jd-hero {
  padding: 64px 100px 24px 100px;
  .heading {
    font-size: 48px;
  }
  .paragraph {
    font-size: 16px;
  }
}

.jd-content {
  padding: 64px 100px 24px 100px;
  gap: 32px;
  .row {
    display: flex;
    gap: 18px;
  }
  .listed-items {
    gap: 12px;
  }
  .paragraph {
    font-size: 18px;
  }
}

.job-form {
  padding: 46px 100px;
  column-gap: 43px;
  .job-form-header {
    row-gap: 16px;
  }
  .basic-details {
    row-gap: 24px;
    column-gap: 16px;
  }
}
