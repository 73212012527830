$unit: rem;

$ULTRA_SMALL: .375 + $unit; // 6px
$MICRO_SMALL: .5 + $unit; // 6px
$EXTRA_SMALL: .625 + $unit; // 10px
$SMALL: .75 + $unit; // 12px
$MEDIUM: .875 + $unit; // 14px
$LARGE: 1 + $unit; // 16px
$M_LARGE: 1.125 + $unit; // 18px
$X_LARGE: 1.25 + $unit; // 20px
$XX_LARGE: 1.5 + $unit; // 24px
$_XXX_LARGE: 2 + $unit; // 32px
$XXX_LARGE: 2.25 + $unit; // 36px
$XXXX_LARGE: 3 + $unit; // 48px
$XXXXX_LARGE: 3.5 + $unit; // 56px

$LINE_HEIGHT: 100%;
$LINE_HEIGHT_MIN: 120%;
$LINE_HEIGHT_MAX: 150%;
$LINE_HEIGHT_MEDIUM: 130%;

$REGULAR:normal;
$BOLD_300:300;
$BOLD_400:400;
$BOLD_500:500;
$BOLD_600:600;
$BOLD_700:700;
$BOLD_800:800;
$BOLD_900:900;