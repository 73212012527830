.search-dropdown {
	position: relative;
	left: 0 !important;
	z-index: 10;
	background: white;
	border-collapse: collapse;
	border-radius: 0 0 0.374rem 0.374rem;

	.search-dropdown-item {
		padding-left: 13px;
		margin-top: 0;
		margin-bottom: 0;
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	//   border: 1px solid black;
	margin-top: 0 !important;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	max-height: 220px;
}

.lower-border-radius-remove {
	border-radius: 0.374rem 0.374rem 0 0;
	padding: 1px 1px 0.5px 1px;
}

.upper-border-radius-remove {
}

.search-dropdown-wrapper {
	padding: 0px 1px 1px 1px;
	border-radius: 0 0 0.375rem 0.375rem;
	background-image: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
}

.search-input-wrapper {
	//   border-width: 1px;
	padding: 1px;
	border-radius: 0.374rem;
	// height: 40px;
	//   border: 1px solid transparent;
	background-image: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);

	:nth-child(1) {
		border-radius: 0.345rem 0 0 0.345rem;
	}

	:nth-child(2) {
		background-color: white;
	}

	// border: 1px solid var(--grd-primary, #F6A200);
	// background: var(--Neutral-10, #fff);
	// box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
}

.search-input-wrapper-open {
	border-radius: 0.374rem 0.374rem 0 0;
	padding: 1px 1px 1px 1px;
	// height: 40px;
	//   border: 1px solid transparent;
	background-image: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);

	:nth-child(1) {
		border-radius: 0.374rem 0 0 0;
	}

	:nth-child(2) {
		border-radius: 0 0.374rem 0 0;
		background-color: white;
	}
}

.search-input {
	transition: none;
	border: none;
	box-shadow: none;
	color: #979797;
}

.search-input:focus {
	border: none;
	box-shadow: none;
}

.highlight-dropdown-item {
	background: rgba(234, 49, 98, 0.14);
	cursor: pointer;
}

.search-dropdown-item:hover {
	background: rgba(234, 49, 98, 0.14);
}

.search-icon {
	display: flex;
	justify-content: start;
	padding-top: 4rem;

	@media (max-width: 767.98px) {
		padding-top: 1rem;
		justify-content: center;
	}
}

.search-pill-wrapper {
	padding: 0px;
	font-size: 10px;
	height: fit-content;
	margin-top: 8px;

	.pill-wrapper {
		padding: 3px 8px;

		@media (max-width: 767.98px) {
			font-size: 8px;
		}

		font-size: 10px;
		border: 1.5px solid rgb(255, 138, 101);
	}
}

.ferrous-items {
	// padding: 5px 10px 5px 10px;
	font-weight: 600;
	max-width: 400px;

	@media (max-width: 767.98px) {
		width: 290px;
		// padding-top: 1rem;
		// padding: 5px 10px 5px 10px;
		padding: 0 0 10px 10px;
		justify-content: center;
	}
}
