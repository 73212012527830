@import './theme.scss';
@import './text.scss';
@import './spacings.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';

@import 'css/header';
@import 'css/about-us';
@import 'css/banner';
@import 'css/finance';
@import 'css/home';
@import 'css/products';
@import 'css/blogs';
@import 'css/services';
@import 'css/recycling';
@import 'css/footer';
@import 'css/search';
@import 'css/policy';
@import 'css/careers';

@import 'css/sun-editor';
@import 'css/base';

// * {
//   font-family: var(--font-poppins);
// }

:root {
	height: 100%;
	width: 100%;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
	width: 100%;
	// background-color: #F5F6F7;
}

a {
	text-decoration: none !important;
	color: unset;
}

p {
	margin: 0;
}

nav {
	margin: 1rem 0rem;
	@media (max-width: 767.98px) {
		margin: 0px !important;
		padding: 0.5rem !important;
	}
}

#navbar {
	background-color: #ffffff;
	position: sticky;
	top: 0;
	width: 100%;
	display: block;
	transition: transform 150ms ease-in-out;
	z-index: 10;
}

nav.nav-bar--hidden {
	transform: translateY(-100%);
	@media (max-width: 767.98px) {
		transform: none;
	}
}

.nav-link-hover {
	&:hover {
		color: #101010 !important;
	}
}

.btn {
	padding: $SPACING_3B $SPACING_6B;
	font-size: $MEDIUM;
	border-radius: $SPACING_3B;
	@media (max-width: 767.98px) {
		border-radius: $SPACING_2B;
		padding: 12px;
	}
}

.btn-primary {
	background: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
	color: $text-white;
	font-size: 14px;
	font-weight: 600;
}

.btn-secondary {
	background: #eee;
	color: #202225;
	font-size: 14px;
	font-weight: 600;
}

.nav-link {
	color: $text-grey;
}

.navbar-nav {
	gap: $SPACING_15B;
	@media (max-width: 991.98px) {
		gap: $SPACING_5B;
	}
}

.full-height {
	// height: 100vh;
	@media (max-width: 767.98px) {
		// height: 90vh;
	}
}

.heading {
	color: #202225;
	font-size: 48px;
	font-style: normal;
	font-weight: 600;
	line-height: 56px;
	letter-spacing: -0.96px;
	margin-bottom: $SPACING_2B;
	@media (max-width: 767.98px) {
		font-size: 32px;
	}
}

.heading2 {
	color: #313336;
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 0px;
	@media (max-width: 767.98px) {
		font-size: 14px;
	}
}

.paragraph {
	color: #787f84;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 0px;
	@media (max-width: 767.98px) {
		font-size: 12px;
	}
}

.paragraph2 {
	color: #757b8a;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

.sub-title {
	color: #1d1e25;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	letter-spacing: -0.24px;
}

.cursor-pointer {
	cursor: pointer;
}

.view-more {
	display: flex;
	padding: $SPACING_2B $SPACING_4B;
	justify-content: center;
	align-items: center;
	gap: $SPACING_2B;
	border-radius: $SPACING_2B;
	border: 1px solid $text-grey6;
	background-color: transparent;
	color: $background-black1;
	font-size: $MEDIUM;
	font-weight: $BOLD_600;
}

.video-player {
	height: 100%;
	@media (max-width: 767.98px) {
		iframe {
			height: 15rem;
		}
		width: 100% !important;
	}
	div {
		width: 100% !important;
		height: 100% !important;
	}
}

iframe {
	height: 100%;
	width: 100%;
	border-radius: 20px;
	@media (max-width: 767.98px) {
		height: auto;
	}
}

.card-title {
	font-size: 24px;
	font-weight: 600;
	line-height: 24px;
	color: $text-black3;
	@media (max-width: 767.98px) {
		font-size: 18px;
		line-height: 120%;
		margin-top: 0 !important;
	}
}

.card-span {
	color: $text-grey5;
	font-size: $MEDIUM;
	font-style: normal;
	font-weight: $BOLD_400;
	line-height: $LINE_HEIGHT_MAX;
	@media (max-width: 767.98px) {
		font-size: $SMALL;
	}
}

.primary-text {
	background: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.secondary-border {
	background: linear-gradient(white, white) padding-box, linear-gradient(0deg, #b7b7b7 15.29%, #141416 89.33%) border-box;
	border: 2px solid transparent !important;
}

//////////////////// Start scroll-tab ///////////////////
.scroll-tab {
	display: flex;
	min-height: $SPACING_9B;
	overflow: scroll hidden;
	width: fit-content;
	border-radius: 0.5rem;
	white-space: nowrap;
	text-align: center;
	font-size: $MEDIUM;
	gap: 12px;
	-ms-overflow-style: none;
	/* Internet Explorer 10+ */
	scrollbar-width: none;

	/* Firefox */
	@media (max-width: 767.98px) {
		min-height: $SPACING_2B;
		margin: 1rem 0px;
	}
}

.scroll-selected-tab {
	display: flex;
	padding: 4px 20px;
	align-items: center;
	gap: 6px;
	border-radius: 8px;
}

.scroll-disabled-tab {
	display: flex;
	padding: 4px 20px;
	align-items: center;
	gap: 6px;
	border-radius: 8px;
	border: 1px solid #dfe1e7;
}

//////////////////// End scroll-tab ///////////////////

/////////////////// Start progress multisteps //////////////

.steps-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	position: sticky;
}

.steps-container .step-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background: #2ed573;
	transition: all 200ms ease;
	transition-delay: 0s;

	img {
		width: auto;
		min-width: auto;
		min-height: auto;
	}
}

.steps-container .step-circle.inactive {
	background: #e0e0e0;
}

.progress-bar-container {
	position: absolute;
	height: 2px;
	width: 100%;
	background: #e0e0e0;
	z-index: -1;
}

.progress-bar-container .progress-indicator {
	position: absolute;
	height: 100%;
	width: 0%;
	background: #3ec082;
	transition: all 300ms ease;
}

/////////////////// End progress multisteps //////////////

.tag-text-direction {
	text-align: left;
	text-wrap: wrap;
}

.dropdown-item:active {
	background-color: #f8f9fa !important;
}

.display-mobile-view {
	display: none !important;
	margin-left: 1rem;

	@media (max-width: 767.98px), (max-width: 991.98px) {
		margin: 1rem 0rem 1rem 0.5rem;
		display: flex !important;
		flex-direction: column;
		gap: 10px;
	}
}
.display-laptop-view {
	display: block;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		display: none !important;
	}
}

.not-found {
	display: flex;
	flex-direction: row;
	// flex-wrap:wrap;
	@media (max-width: 767.98px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-shrink: initial;

		:nth-child(1) {
			width: 100%;
			margin-bottom: 16px;
			padding-left: 0;
			padding-right: 0;
		}
		:nth-child(2) {
			.heading {
				font-size: 20px;
				margin-bottom: 5px;
			}
		}
	}
	.link-to-home {
		background: -webkit-linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	img {
		width: 100%;
		@media (max-width: 767.98px) {
			width: 100%;
		}
	}
}

////////////// youtube modal //////////////

.modal-body {
	padding: 0rem;
}

.modal-content {
	background-color: transparent;
	border: none;
}

.modal-header {
	border-bottom: 0rem;
	justify-content: flex-end;
	padding: 0rem;
}

.modal-video-player {
	height: 30rem;
	@media (max-width: 767.98px) {
		height: 15rem;
	}
}

.numeric-input {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.toast {
	--bs-toast-max-width: 26vw;
}
.search-toast {
	position: fixed !important;
	z-index: 999;
	bottom: 1rem;
	left: 1rem;
	border: unset !important;
	box-shadow: none !important;
	background-color: unset !important;
}
.toast-header {
	border-bottom: unset !important;
	background: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
	color: #eee;
}

.wati-sticky-icon {
	width: fit-content;
	// @media (max-width: 767.98px) {
	display: flex;
	justify-content: end;
	align-items: start;

	span {
		display: flex;
		border-radius: 50%;
		width: fit-content;
		height: fit-content;
		color: white;
		background-color: #00e785;
		padding: 10px 10px;
		box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.4);
	}
	visibility: visible;
	position: fixed;
	z-index: 2;
	right: 2rem;
	bottom: 7rem !important;
	svg {
		font-size: 25px;
	}
	animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.1, 1.1);
	}
	100% {
		transform: scale(1, 1);
	}
}

.form-control::placeholder {
	color: #bdbdbd;
	opacity: 1;
}

.opacity-75 {
	opacity: 0.8 !important;
}

/* ===== MAIN CODE ===== */
.content-hghlght {
	display: flex;
	justify-content: space-evenly;
	pointer-events: none;
	transition: all 0.4s;
}

.card-hghlght {
	pointer-events: auto;
	transform: scale(1);
	transition: all 0.4s;
}

.card-hghlght:hover {
	opacity: 1;
	transform: scale(1.02);
	border: 1.2px solid !important;
	box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.content-hghlght:hover .card-hghlght:not(:hover) {
	opacity: 0.5;
	transform: scale(0.9);
}

/* ===== MAIN CODE ===== */

/* ===== tab css ===== */

.nav-tabs{
	@media (max-width: 767.98px) {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	overflow-x: auto;
	/* Hide scrollbar for Chrome, Safari and Opera */
	::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;	
	}
}

.tab-content{
  width: 100%;
}

.nav-tabs .nav-link {
	border: none; 
	font-size: $SPACING_6B;
	font-weight: $BOLD_600;
	@media (max-width: 767.98px) {
		font-size: $SPACING_3B;
	}
}

.nav-link:focus, .nav-link:hover {
	color: #101010;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	border-bottom: 3px solid $background-red;
	color:$background-red;
	font-size: $SPACING_6B;
	background-color: unset;
	@media (max-width: 767.98px) {
		font-size: $SPACING_3B;
	}
}

/* ===== tab css ===== */

/* ===== Table css ===== */

.table-responsive {
	border-radius: $SPACING_2B;
}

.roundedTable{
	margin-bottom: 0rem;
	border-radius: $SPACING_2B;
	overflow: hidden;
	border: 1px solid $text-grey5;
}



