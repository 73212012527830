//////////////// Start Category Section //////////////
///
.category-container {
	padding: $SPACING_16B $SPACING_18B;
	background-color: $background-lightgrey;
	display: flex;
	flex-direction: column;
	gap: $SPACING_14B;

	@media (max-width: 767.98px) {
		padding: 40px 24px;
		gap: 40px;
	}
}

.category-card {
	border-radius: $SPACING_4B;
	overflow: hidden;
	height: 100%;

	@media (max-width: 767.98px) {
		padding: 0;
	}
}

.category-card img {
	object-fit: contain;
	width: 100%;
	height: 100%;

	@media (max-width: 767.98px) {
		height: 7rem;
		object-fit: fill;
	}
}

//////////////// End Category Section //////////////

/////////////// Start Service Section /////////////

.service-container {
	background-image: url('../../assets/home/services/services-right.png'), url('../../assets/home/services/services-left.png');
	background-position: right top, left bottom;
	background-repeat: no-repeat;
	padding: $SPACING_16B $SPACING_18B;
	display: flex;
	gap: 3.5rem;
	//   height: 100vh;

	@media only screen and (max-width: 1024px) {
		padding: $SPACING_16B $SPACING_2B;
	}

	@media (max-width: 767.98px) {
		gap: $SPACING_8B;
		background-image: url('../../assets/home/services/services-right-mb.png'), url('../../assets/home/services/services-left-mb.png');
		padding: 30px 0px;
		height: auto;
	}
}

.service-content {
	display: flex;
	flex-direction: row;
	//   gap: 1rem;
	@media (max-width: 767.98px) {
		flex-direction: column;
	}
	.service-content-video-player {
		height: 100%;
		width: 100%;
	}
}

.service-content > .col-md-6 {
	padding: 0rem 1rem;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		padding: 0rem;
	}
}

.service-child-card {
	display: flex;
	gap: 60px;
	padding: $SPACING_8B $SPACING_8B;
	border-radius: 20px;
	height: 100%;
	cursor: pointer;

	&:hover {
		background: linear-gradient(white, white) padding-box, linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%) border-box;
		border: 1px solid transparent !important;
	}

	@media (max-width: 767.98px) {
		padding: 16px 12px;
		gap: 30px;
		height: 210px;
	}

	.in-1 {
		// margin-bottom: 3rem;
		display: flex;
		justify-content: space-between;
	}

	h2 {
		color: $text-black2;
		font-size: $M_LARGE;
		font-style: normal;
		font-weight: $BOLD_600;
		line-height: $LINE_HEIGHT;

		/* 19.8px */
		@media (max-width: 767.98px) {
			font-size: 16px;
		}
	}

	p {
		color: rgba(16, 16, 16, 0.6);
		font-size: $MEDIUM;
		font-style: normal;
		font-weight: $BOLD_400;
		line-height: normal;
		margin-bottom: 0px;

		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
}

.card-selected {
	background: linear-gradient(white, white) padding-box, linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%) border-box;
	border: 1px solid transparent !important;
}

.border-icon {
	// border: 1px solid;
	// padding: 5px;
	// border-radius: 10px;
	img {
		width: 33.333px;
		height: 33.333px;
	}
}

/////////////// End Service Section /////////////

////////////// Start Ready to order /////////////

.ready-to-order-container {
	padding: $SPACING_16B $SPACING_18B;
	background: linear-gradient(332deg, $background-greenlight -9.25%, $background-greenlight2 40.98%);

	@media (max-width: 767.98px) {
		text-align: center;
		padding: 32px 16px;
	}

	h1 {
		color: $text-white;
		font-size: 3.125rem;
		font-style: normal;
		font-weight: $BOLD_700;
		line-height: $LINE_HEIGHT_MEDIUM;
		/* 130% */
		letter-spacing: 0.5px;

		@media (max-width: 767.98px) {
			font-size: 32px;
		}
	}

	p {
		color: $text-white;
		font-size: $M_LARGE;
		font-style: normal;
		font-weight: $BOLD_300;
		line-height: $LINE_HEIGHT_MAX;
		/* 155.556% */

		@media (max-width: 767.98px) {
			font-size: 14px;
		}
	}
}

////////////// End Ready to order /////////////

////////////// Start Market News /////////////

.market-news-container {
	background-color: $background-lightgrey;
	padding: $SPACING_22B 0px 0px;
	display: flex;
	flex-direction: column;

	@media (max-width: 767.98px) {
		padding: 40px 20px;
	}
}

.action-button {
	display: none !important;

	@media (max-width: 767.98px) {
		display: block !important;
	}
}

.swiper-mobile {
	display: block !important;

	@media (max-width: 767.98px) {
		display: none !important;
	}
}

.news-card {
	border-radius: $SPACING_3B;
	display: flex;
	padding: $SPACING_3B $SPACING_3B;
	flex-direction: column;
	align-items: flex-start;
	gap: $SPACING_1B;

	@media (max-width: 767.98px) {
		padding: $SPACING_2B;
	}

	h3 {
		color: $text-black3;
		font-size: $LARGE;
		font-weight: $BOLD_600;
		margin-bottom: 0;

		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}

	span {
		margin-bottom: 0;
		color: $text-grey2;
		font-weight: $BOLD_400;
		font-size: $EXTRA_SMALL;

		@media (max-width: 767.98px) {
			font-size: 10px;
		}
	}

	.time-ago {
		font-weight: 500;
	}

	.box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $SPACING_10;
		align-self: stretch;
		p {
			color: var(--Greyscale-900, #0d0d12);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 155%;
			@media (max-width: 767.98px) {
				font-size: 12px;
			}
		}
		span {
			// color: var(--Neutral-10, #fff);
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 155%;
			@media (max-width: 767.98px) {
				font-size: 8px;
			}
		}
	}

	.tag {
		display: flex;
		padding: $ULTRA_SMALL $EXTRA_SMALL;
		align-items: center;
		gap: $SPACING_1;
		border-radius: $SPACING_6;
		// background: $background-green2;
		// color: $background-green2;
		color:green;
		font-size: $EXTRA_SMALL;
		font-weight: $BOLD_700;

		@media (max-width: 767.98px) {
			padding: $SPACING_1B;
		}
	}
	.tag-red {
		// background: $background-red;
		color:red;
	}

	b {
		font-weight: $BOLD_500;
		font-size: $MEDIUM;
		color: $text-grey2;

		@media (max-width: 767.98px) {
			font-size: 10px;
		}
	}

	h5 {
		color: $text-black3;
		font-size: $LARGE;
		font-style: normal;
		font-weight: $BOLD_700;
		line-height: normal;
		margin-bottom: 0;

		@media (max-width: 767.98px) {
			font-size: 10px;
		}
	}

	.muted-text {
		color: $text-grey2;
		font-size: $EXTRA_SMALL;
		font-style: normal;
		font-weight: $BOLD_400;
		line-height: 155%;
		@media (max-width: 767.98px) {
			font-size: 10px;
		}
	}
}

.market-section-2 {
	padding: $SPACING_20B $SPACING_24B;
	@media (max-width: 767.98px) {
		padding: $SPACING_12B 0 0 0;
	}

	.label {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.entry-summary {
		@extend .label;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-clamp: 4;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}

	.text-1 {
		color: var(--Greyscale-900, $text-black3);
		font-size: $M_LARGE!important;
		font-weight: $BOLD_500;
		line-height: $LINE_HEIGHT_MIN;
		margin-bottom: 0;
		@media (max-width: 767.98px) {
			font-size: 18px;
		}
	}

	.subtitle {
		color: $text-grey4;
		font-size: $MEDIUM;
		font-style: normal;
		// font-weight: $BOLD_400;
		line-height: 140%;
		@media (max-width: 767.98px) {
			font-size: $SMALL;
		}
		/* 18.9px */
	}

	.news-date{
		border-top-left-radius: $EXTRA_SMALL;
	}
}

////////////// End Market News /////////////

///////////// Start Choose Us ///////////////

.choose-us-container {
	background-image: url('../../assets/choose-us/bg-right.png'), url('../../assets/choose-us/bg-left.png');
	background-position: right top, left bottom;
	background-repeat: no-repeat;
	padding: $SPACING_24B 0rem;
	display: flex;
	flex-direction: column;

	@media (max-width: 767.98px) {
		background-image: none;
		// background-image: url("../../assets/choose-us/bg-right-mb.png"), url("../../assets/choose-us/bg-left-mb.png");
		padding: 56px 0rem;
		height: auto;
	}
}

.title {
	font-size: $XXXX_LARGE;
	color: $text-grey4;
	font-style: normal;
	font-weight: $BOLD_500;
	line-height: 125%;
	@media (max-width: 767.98px) {
		font-size: 32px;
	}
}

.choose-sub-heading {
	color: $text-grey7;
	font-size: $LARGE;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 1rem;

	@media (max-width: 767.98px) {
		font-size: 16px;
	}
}

.choose-title {
	color: var(--Neutral-100, #1d1e25);
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
	/* 31.2px */
}

.choose-sub-title {
	color: $text-grey7;
	font-size: $MEDIUM;
	font-style: normal;
	font-weight: $BOLD_400;
	line-height: normal;
	@media (max-width: 767.98px) {
		font-size: 12px;
	}
}

.choose-action {
	margin-top: 2rem;
	@media (max-width: 767.98px) {
		margin-top: 1rem;
	}
	.choose-wapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1.2rem;
		@media (max-width: 767.98px) {
			gap: 1rem;
		}
	}
}

.choose-img {
	width: 100%;
	height: 100%;
	padding: 0px 1rem;

	@media (max-width: 767.98px) {
		// height: 20rem;
		padding: 2rem 0px 0rem;
	}
}

///////////// End Choose Us ///////////////

///////////// Start partner  ///////////////

.partner-container {
	padding: $SPACING_24B $SPACING_24B;
	background: $background-black1;
	color: $text-white;

	@media (max-width: 767.98px) {
		padding: 40px 16px;
		gap: 22px;
	}

	.partner-heading {
		padding-bottom: 80px;

		@media (max-width: 767.98px) {
			padding-bottom: 20px;
		}

		.text1 {
			color: $text-white;
			font-size: $XXXXX_LARGE;
			font-style: normal;
			font-weight: $BOLD_600;
			line-height: $LINE_HEIGHT_MIN;
			/* 67.2px */

			@media (max-width: 767.98px) {
				font-size: 28px;
			}
		}

		.text2 {
			color: #7e8492;
			font-size: $M_LARGE;
			font-weight: $BOLD_500;

			@media (max-width: 767.98px) {
				font-size: 16px;
				margin-top: 10px;
			}
		}
	}

	.partner-card-container {
		padding: $SPACING_4B;
		border-radius: $SPACING_4B;
		background: $text-white;
		color: $text-black4;
		flex: 1;
		@media (max-width: 767.98px) {
			// height: 28vh;
		}
	}

	.partner-count {
		/* Heading H2/poppins/Semi Bold */
		font-size: $XXXXX_LARGE;
		font-weight: $BOLD_600;
		line-height: $LINE_HEIGHT_MIN;
		background: var(--Primary-grad, linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		letter-spacing: -0.56px;

		@media (max-width: 767.98px) {
			font-size: 18px;
			font-weight: $BOLD_700;
		}
	}

	.text-inner-1 {
		color: $text-black4;
		font-size: $M_LARGE;
		font-weight: $BOLD_600;
		line-height: $LINE_HEIGHT_MIN;
		/* 21.6px */
		margin-bottom: $SPACING_10;

		@media (max-width: 767.98px) {
			font-size: 14px;
		}
	}

	.text-inner-2 {
		color: $text-grey7;
		font-size: $LARGE;
		font-weight: $BOLD_400;
		margin-bottom: 0;

		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
}

///////////// End partner  ///////////////

///////////// Start people  ///////////////

.bg-people-container {
	background-image: url('../../assets/people-talking/bg-right.png'), url('../../assets/people-talking/bg-left.png');
	background-position: right bottom, left top;
	background-repeat: no-repeat;

	@media (max-width: 767.98px) {
		background-image: none;
	}
}

.people-container {
	display: flex;
	padding: $SPACING_24B $SPACING_24B 0px;
	flex-direction: column;
	align-items: center;
	gap: $SPACING_14B;

	@media (max-width: 767.98px) {
		padding: 40px 20px;
		gap: 41px;
	}
}

.people-card {
	display: flex;
	padding: $SPACING_8B;
	align-items: center;
	gap: $SPACING_7B;
	flex: 1 0 0;
	border-radius: $SPACING_4B;
	background: #f9f9f9;
	width: 100%;

	@media (max-width: 767.98px) {
		flex-direction: column;
	}

	.vedio-icon {
		position: absolute;
		top: 22%;
		bottom: 50%;
		left: 32%;
		right: 50%;
	}
}

.inner-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: $SPACING_4B;
}

.card-description {
	overflow: hidden;
	color: $text-grey10;
	text-overflow: ellipsis;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.16px;
	@media (max-width: 767.98px) {
		font-size: 12px;
		line-height: 24px;
	}
}

.people-card-title {
	color: $text-black6;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}

.card-sub-title {
	color: $text-grey10;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.14px;
	@media (max-width: 767.98px) {
		font-size: 12px;
	}
}

.people-container-swiper {
	padding: $SPACING_6B $SPACING_24B;

	@media (max-width: 767.98px) {
		padding: $SPACING_6B $SPACING_4B;
	}
}

.testimonial-card {
	display: flex;
	padding: $SPACING_9B $SPACING_7B;
	flex-direction: column;
	justify-content: space-between;
	border-radius: $SPACING_4B;
	background: $background-lightgrey;

	b {
		color: $background-black1;
		font-size: $LARGE;
		font-weight: $BOLD_600;
		line-height: $LINE_HEIGHT_MAX;
		/* 150% */
	}

	.card-header {
		border-bottom: unset;
		background-color: unset;
	}
}

///////////// End people  ///////////////

///////////// Start Offer banner  ///////////////

.offer-banner-container {
	background: linear-gradient(104deg, $background-greenlight3 -4.53%, $background-greenlight2 111.52%);
	padding: $SPACING_20B;
	// margin: $SPACING_20B 0px;

	@media (max-width: 767.98px) {
		margin: 0px;
		padding: 40px 20px;
	}

	.offer-banner-inner {
		background-image: url('../../assets/offer-banner/left-top-g.png'), url('../../assets/offer-banner/left-bottom.png'),
			url('../../assets/offer-banner/right-top.png'), url('../../assets/offer-banner/right-bottom-g.png');
		background-position: left top, left bottom, right top, right bottom;
		background-repeat: no-repeat;
		border-radius: $SPACING_4B;
		background-color: $background-black1;
		padding: 4rem 7rem 4rem 3.5rem;

		@media (max-width: 767.98px) {
			background-image: none;
			padding: 35px 13px;
		}

		@media (max-width: 991.98px) {
			padding: 3rem;
		}

		h1 {
			color: $text-white;
			font-size: $XXXX_LARGE;
			font-weight: $BOLD_600;
			line-height: $LINE_HEIGHT_MEDIUM;
			/* 62.4px */
			letter-spacing: -0.96px;
			margin-bottom: $SPACING_2B;

			@media (max-width: 767.98px) {
				font-size: 32px;
			}
		}
	}

	.schedule-demo {
		display: flex;
		margin-top: 1rem;
		justify-content: flex-end;
		@media (max-width: 767.98px) {
			flex-wrap: wrap;
			flex-direction: column;
		}
		@media (max-width: 991.98px) {
			justify-content: center;
		}
	}
}

///////////// End Offer banner  ///////////////

///////////// Start download app  ///////////////

.download-section-container {
	background-image: url('../../assets/download-app/download-left.png'), url('../../assets/download-app/download-center.png'),
		url('../../assets/download-app/download-right.png');
	background-position: left bottom, center, right top;
	background-repeat: no-repeat;
	background-size: 30rem, 46rem, 30rem;
	padding: 4rem 0px;

	@media (max-width: 767.98px), (max-width: 991.98px) {
		background-image: none;
		padding: 0px;
	}

	.download-section-inner {
		border-radius: $SPACING_6B;
		background: $text-grey9;
		margin: 7.5rem;

		@media (max-width: 767.98px), (max-width: 991.98px) {
			margin: 56px 20px;
			background-color: #eaf4f0;
			backdrop-filter: blur(4.150000095367432px);
			margin: 0rem;
			border-radius: 0px;
			padding: 26px;
		}
	}

	.download-image-container {
		img {
			margin-top: -50px;
			width: 100%;

			@media (max-width: 767.98px), (max-width: 991.98px) {
				display: none;
			}
		}
	}

	.download-image-container1 {
		display: flex;
		justify-content: center;
		img {
			display: none;
			width: 15rem;

			@media (max-width: 767.98px), (max-width: 991.98px) {
				display: block;
			}
		}
	}

	.download-title {
		color: #1d1e25;
		font-size: $_XXX_LARGE;
		font-weight: 700;

		@media (max-width: 767.98px) {
			font-size: 24px;
		}
	}

	.download-subtitle {
		color: #676767;
		font-size: $MEDIUM;
		font-weight: 400;
		line-height: normal;

		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}

	.text-area-download {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		@media (max-width: 767.98px), (max-width: 991.98px) {
			text-align: center;
			margin: 1rem 0rem;
		}
	}
}

.download-link-store {
	margin-top: 1.5rem;
	a:first-of-type {
		margin-right: 1.5rem;
	}
	@media (max-width: 767.98px), (max-width: 991.98px) {
		justify-content: center;
	}
}

///////////// End download app  ///////////////

.fabrication {
	width: 100%;
	//   height: 35.5rem;
	object-fit: cover;
	border-radius: 1rem;
	@media (max-width: 767.98px) {
		margin-top: 2rem;
		height: 15rem;
	}
}

////// industries ///////////

.industries-home-container {
	display: flex;
	padding: 55px 100px;
	flex-direction: column;
	align-items: center;
	gap: 67px;
	align-self: stretch;
	@media (max-width: 767.98px) {
		padding: 48px 0px;
		gap: 60px;
		.heading {
			font-size: 32px;
			line-height: 130%;
			letter-spacing: -0.64px;
		}
		.paragraph2 {
			font-size: 14px;
			font-weight: 400;
			line-height: 155%;
			text-align: justify;
		}
	}
}

.xs-industries-home {
	display: flex;
	@media (max-width: 767.98px) {
		display: flex;
	}
}

.industries-container {
	margin-bottom: 100px;
	@media (max-width: 767.98px) {
		margin-bottom: 50px;
	}
}
