.pill-wrapper {
	width: fit-content;
	padding: 4px 10px;
	border-radius: 20px;
	font-size: 14px;
}

.pill-primary {
	background: linear-gradient(90deg, #f6a200 -29.7%, #ea3162 100.3%);
	color: #fff;
}

.pill-secondary {
	background: #212529;
	color: #dee2e6;
}

.pill-tertiary {
	background: #fff;
	color: #000;
	border: 1px solid;
	font-weight: 600;
}

.pill-quaternary {
	// background: -moz-linear-gradient(90deg, #f6a200 -29.7%, #ea3162 100.3%);
	// -moz-background-clip: text;
	// background-clip: text;
	font-weight: 700;
	border: 2px solid #ced4da;
	:nth-child(1) {
		stroke: rgb(255, 138, 101);
		font-weight: 500;
	}

	:nth-child(2) {
		background: linear-gradient(90deg, #f6a200 -29.7%, #ea3162 100.3%);
		background: -webkit-linear-gradient(90deg, #f6a200 -29.7%, #ea3162 100.3%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: transparent;
	}
}
