.row-banner {
	@media (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
}
.banner-title {
	// color: var(--Neutral-07, #202225);
	font-size: 42px;
	margin-top: 10px;
	margin-bottom: 10px; 
	font-style: normal;
	font-weight: 600;
	text-wrap: nowrap;
	overflow: visible;
	width: 23rem;
	/* 129.412% */
	letter-spacing: -2px;

	@media (max-width: 767.98px) {
		width: 0rem;
		font-size: 32px;
		text-align: center;
		margin-top: 15px;
		margin-bottom: 15px;
		width: 100%;
	}
}

.banner-subheading-colored {
	font-size: 22px !important;
	line-height: unset !important;
	background: -webkit-linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	@media (max-width: 767.98px) {
		font-size: 20px !important;
		text-align: center;
		margin-bottom: 20px;
	}
}

.banner-layer {
	background-image: url('../../../public/bg_layer.webp');
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	.shield {
		display: block;
	}

	#mobile-shield {
		display: none;
	}

	@media (max-width: 767.98px) {
		.shield {
			display: none;
		}

		#mobile-shield {
			display: block;
		}
	}

	img {
		width: 100%;
		height: 700px;
		padding-left: 200px;
		padding-bottom: 150px;
		object-fit: contain;
		@media (max-width: 767.98px) {
			height: 300px;
			padding-left: 0px;
			padding-bottom: 0px;
		}
	}
}

.banner-italic-text {
	margin-top: $SPACING_13B;
	color: #676767;
	font-feature-settings: 'salt' on, 'liga' off;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	line-height: 122.3%;
	@media (max-width: 767.98px) {
		margin-top: 0rem;
	}
}

.banner-brands {
	display: flex;
	justify-content: center;
	align-items: center;
}

.banner-brands svg {
	height: $SPACING_18B;
	width: $SPACING_32B;
	margin-inline: $SPACING_2B;
}

.banner-inner {
	margin-top: 6rem;
	margin-left: 4rem;
	display: flex;
	flex-direction: column;
	gap: 15px;
	p {
		color: var(--Neutral-04, #787f84);
		font-size: 28px;
		font-style: normal;
		font-weight: 500;
		line-height: 36px;
		letter-spacing: -0.84px;
	}
	

	@media (max-width: 767.98px) {
		p {
			font-size: 14px;
			line-height: 26px;
		}
		text-align: center;
		margin-top: 0;
		margin-left: 0;
	}

	@media (max-width: 991.98px) {
		margin-top: 0rem;
		margin-left: 0rem;
	}
}

.no-wrap {
	text-wrap: nowrap;
	color: var(--Neutral-04, #787f84);
	font-size: 28px;
	font-style: normal;
	font-weight: 500;
	line-height: 36px;

	@media (max-width: 767.98px) {
		text-wrap: unset;
		font-size: 22px;
		text-align: center;
		margin-top: 20px;
	}
}

.trusted-brands {
	margin-bottom: 1rem;
	@media (max-width: 767.98px) {
		margin-bottom: 0rem;
		display: flex;
		justify-content: center;
	}
}

.brand-gap {
	gap: 0px 10px;
	@media (max-width: 767.98px) {
		flex-wrap: nowrap;
		width: 100% !important;
	}
}
