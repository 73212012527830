/////// start banner //////
.recycling-banner {
	display: flex;
	padding: 90px 0px 16px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 32px;
	@media (max-width: 767.98px) {
		padding: 40px 0px;
	}
	h2 {
		font-size: 68px;
		font-style: normal;
		font-weight: 600;
		line-height: 125%;
		letter-spacing: -0.68px;
		@media (max-width: 767.98px) {
			font-size: 32px;
			letter-spacing: -0.32px;
		}
	}
	p {
		color: var(--Neutral-04, #787f84);
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.22px;
		@media (max-width: 767.98px) {
			font-size: 16px;
			line-height: 26px;
			letter-spacing: -0.16px;
		}
	}

	img {
		width: 100%;
		height: 100%;
		border-radius: 32px;
	}
    .recycling-video-player {
        height: 30rem;
        @media (max-width: 767.98px) {
            height: auto;
        }
    }
}

/////// end banner //////

/////// start metals recycle //////

.metals-recycle-container {
	display: flex;
	padding: 55px 100px;
	flex-direction: column;
	align-items: center;
	gap: 67px;
	align-self: stretch;
	@media (max-width: 767.98px) {
		padding: 48px 0px;
		gap: 60px;
		.heading {
			font-size: 32px;
			line-height: 130%;
			letter-spacing: -0.64px;
		}
		.paragraph2 {
			font-size: 14px;
			font-weight: 400;
			line-height: 155%;
			text-align: justify;
		}
	}
}
.md-metals-recycle {
	display: flex;
	padding: 0px 56px;
	@media (max-width: 767.98px) {
		display: none;
		padding: 0px;
	}
}
.xs-metals-recycle {
			display: flex;
	@media (max-width: 767.98px) {
		display: flex;
	}
}

.xs-card {
	display: flex;
	// padding: 16px;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 0.2rem;
	// border-radius: 8px;
	border: 1px solid #7e8492;
	// background: var(--Background-White, #fff);
	// box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
	h5 {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 135%;
	}
	p {
		color: var(--Greyscale-500, #666d80);
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 155%;
	}
}
/////// end metals recycle //////

/////// start moving towards //////
.recycling-moving-towards-header {
	display: flex;
	padding: 112px 0px;
	flex-direction: column;
	align-items: center;
	gap: 80px;
	align-self: stretch;
	@media (max-width: 767.98px) {
		padding: 48px 0px;
		gap: 60px;
	}
	.text1 {
		color: var(--Neutral-07, #202225);
		font-size: 48px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		@media (max-width: 767.98px) {
			font-size: 32px;
			line-height: 130%;
			letter-spacing: -0.64px;
			text-align: center;
		}
	}
	.text2 {
		color: var(--Neutral-04, #787f84);
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: -0.22px;
		@media (max-width: 767.98px) {
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 21px;
		}
	}
}

.moving-towards-card {
	padding: 2rem 1.5rem;
	border-radius: 1rem;
	background: #ffffff;
	color: #1d1e25;

	h2 {
		font-size: 56px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
		letter-spacing: -0.56px;
		@media (max-width: 767.98px) {
			font-size: 48px;
		}
	}

	h6 {
		color: #1d1e25;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
		/* 21.6px */
	}
	p {
		color: #7e8492;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
/////// end moving towards //////

///////// start flip card //////////

.flip-card {
	background-color: transparent;
	width: 100%;
	height: 25rem;
	perspective: 1000px;
	overflow: hidden;
	border-radius: 24px;
	img {
		width: 100%;
		height: 100%;
	}
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 24px;
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-card-front {
	img {
		object-fit: fill;
		border-radius: 24px;
	}

	h3 {
		bottom: 3rem;
		color: var(--Colors-Basic-Color-3, #fff);
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 34px;
		/* 106.25% */
		// letter-spacing: -0.8px;
		.flip-card-front-title {
			line-height: 125%;
			padding: 6px;
			background: $text-grey;
			// background: black;
			// border-radius: 10px;
			box-shadow: 0 7px 9px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
			border-bottom-right-radius: 24px;
			border-bottom-left-radius: 24px;
		}
	}
}

.flip-card-back {
	transform: rotateY(180deg);
	display: flex;
	width: 100%;
	height: 25rem;
	padding: 32px;
	flex-direction: column;
	align-items: flex-start;

	gap: 24px;
	border-radius: 24px;
	border: 1px solid #7e8492;
	background: var(--Background-White, #fff);
	box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);

	h6 {
		color: var(--Greyscale-900, #0d0d12);
		font-size: 32px;
		font-style: normal;
		font-weight: 500;
		line-height: 135%;
		/* 43.2px */
	}
	p {
		color: var(--Greyscale-500, #666d80);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 155%;
		text-align: justify;
		overflow: hidden;
		/* 27.9px */
	}
}

//////// end flip card ///////

///////// start enabling world /////////
.enabling-world-container {
	display: flex;
	padding: 68px 80px;
	margin-bottom: 68px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	align-self: stretch;
	border-radius: 24px;
	border: 1px solid #7e8492;
	background-image: url('../../assets/recycling/bg-enabling-world.webp');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	@media (max-width: 767.98px) {
		padding: 40px 0px;
		gap: 32px;
	}
	.heading,
	.paragraph2 {
		color: #ffffff;
		@media (max-width: 767.98px) {
			text-align: center;
		}
	}

	.brand-card-wapper {
		@media (max-width: 767.98px) {
			flex-direction: column;
			gap: 16px;
		}
	}

	.brand-card {
		display: flex;
		padding: 0px 41.667px 0px 41px;
		justify-content: center;
		align-items: center;
		flex: 1 0 0;
		border-radius: 16px;
		background: var(--Background-White, #fff);
		box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
		@media (max-width: 767.98px) {
			padding: 0rem;
			img {
				border-radius: 16px;
			}
		}
	}
}
///////// end enabling world /////////
