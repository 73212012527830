.about-us-banner {
	display: flex;
	align-items: center;
	@media (max-width: 767.98px) {
		padding: 27px 0px;
		flex-direction: column;
		gap: 40px;
	}
	h2 {
		font-size: 60px;
		font-weight: 600;
		line-height: 75px;
		letter-spacing: -0.01em;
		@media (max-width: 767.98px) {
			font-size: 32px;
			line-height: 40px;
			text-align: center;
		}
	}
}

.circle-img {
	//  @media (max-width: 767.98px) {
	width: 100%;
	height: 100%;
	//  }
}

/////////// start who we are ///////////

.about-us-who-we-are {
	display: flex;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		padding: 27px 12px;
		flex-direction: column-reverse;
		align-items: normal;
		position: unset;
	}
	.first {
		img {
			@media (max-width: 767.98px), (max-width: 991.98px) {
				width: 100%;
				height: 100%;
			}
		}
	}
	.second {
		padding: 0px 3rem;
		@media (max-width: 767.98px), (max-width: 991.98px) {
			position: unset;
			padding: 0px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

.who-we-are-content {
	color: #1a1b25;
	// text-align:justify;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 125%;
	letter-spacing: -0.52px;
	u {
		background: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	@media (max-width: 767.98px) {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

/////////// end who we are ///////////

////////// start mission values ////////////

.about-mission-values {
	display: flex;
	flex-direction: column;
	padding: 140px 100px 96px;
	gap: 50px;
	@media (max-width: 767.98px) {
		padding: 27px 12px;
		gap: 20px;
	}
}

.about-mission-heading {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	@media (max-width: 767.98px) {
		align-items: center;
	}
	p {
		color: #0d0d12;
		text-align: center;
		font-size: 52px;
		font-style: normal;
		font-weight: 600;
		line-height: 125%;
		letter-spacing: -0.52px;
		@media (max-width: 767.98px) {
			font-size: 24px;
			font-weight: 400;
			letter-spacing: -0.24px;
			text-align: left;
		}
		span {
			color: #7e8492;
			font-weight: 400;
		}
	}
}

.about-mission-content {
	display: flex;
	#mission-value-left-image {
		width: 100%;
		height: 100%;
		border-radius: 40px;
	}
	@media (max-width: 767.98px), (max-width: 991.98px) {
		flex-direction: column;
		gap: 20px;
		#mission-value-left-image {
			display: none;
		}
	}
}

.about-mission-content-right {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 1.5rem;
	.about-content-box {
		display: flex;
		padding: 32px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;
		border-radius: 24px;
		border: 1px solid #7e8492;
		background: var(--Background-White, #fff);
		/* Shadow / Small */
		box-shadow: 0px 1px 3px 0px rgba(13, 13, 18, 0.05), 0px 1px 2px 0px rgba(13, 13, 18, 0.04);
		@media (max-width: 767.98px) {
			gap: 12px;
		}
		h3 {
			color: var(--Greyscale-900, #0d0d12);
			font-size: 32px;
			font-style: normal;
			font-weight: 500;
			line-height: 135%;
			@media (max-width: 767.98px) {
				font-size: 24px;
				margin-bottom: 0rem;
			}
			/* 43.2px */
		}

		p {
			color: var(--Greyscale-500, #666d80);
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 155%;
			@media (max-width: 767.98px) {
				font-size: 14px;
				line-height: normal;
			}
			/* 27.9px */
		}
	}
}

////////// end mission values ////////////

///////// start Our Journey ////////////

.our-journey-container {
	display: flex;
	padding: 54px 0px;
	flex-direction: column;
	align-items: center;
	gap: 50px;
	@media (max-width: 767.98px) {
		gap: 24px;
		padding: 27px 12px;
	}
}

////////// end mission values ////////////

///////// start Our Journey ////////////

.our-journey-container {
	display: flex;
	padding: 54px 0px;
	flex-direction: column;
	align-items: center;
	gap: 50px;
	@media (max-width: 767.98px) {
		gap: 24px;
		padding: 27px 12px;
	}
}

.our-journey-background {
	background-image: url('../../assets/about-us/our-journey.webp');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 61px;
	// height: 100vh;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		height: auto;
		gap: 10px;
	}
	@media (max-width: 767.98px), (max-width: 991.98px) {
		img {
			width: 100%;
			height: 100%;
		}
	}

	h2 {
		color: var(--Neutral-100, #1d1e25);
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	p {
		color: var(--Greyscale-500, #666d80);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		opacity: 0.7;
		@media (max-width: 767.98px) {
			line-height: normal;
		}
	}
}

.our-journey-content-wapper {
	gap: 61px;
	align-items: center;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		gap: 10px;
		flex-direction: column-reverse;
	}
}

.our-journey-content {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.our-journey-item-wapper {
	display: flex;
	flex-direction: row;
	gap: 35px;
	justify-content: space-evenly;
	@media (max-width: 767.98px) {
		gap: 10px;
	}
}

.year-item {
	color: #fff;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #1d1e25;
	font-size: 100px;
	font-style: normal;
	font-weight: 700;
	margin: 0px;
	padding: 0px;
	line-height: 100%;
}

.our-journey-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	span {
		color: var(--Greyscale-500, #666d80);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
	p {
		color: var(--Neutral-100, #1d1e25);
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%;
		@media (max-width: 767.98px) {
			font-size: 14px;
		}
	}

	hr {
		display: flex;
		height: 6px;
		padding-right: 74.625px;
		align-items: center;
		align-self: stretch;
		border-radius: 100px;
		background: var(--Primary-grad, linear-gradient(0deg, #f6a200 -76.29%, #ea3162 89.33%));
		border-top: none;
		opacity: unset;
		@media (max-width: 767.98px) {
			margin: 0.5rem 0;
		}
	}
	@media (max-width: 767.98px) {
		h5 {
			font-size: 12px;
			margin-bottom: 0px;
			font-weight: 600;
		}
		h6 {
			font-size: 12px;
			margin-bottom: 0px;
			font-weight: 400;
		}
	}
}

///////// end Our Journey ////////////

//////// start meet metalbook team ///////
.meet-metalbook-team-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 88px 0px;
	gap: 80px;
	@media (max-width: 767.98px) {
		padding: 27px 12px;
		gap: 0rem;
		align-items: unset;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

.meet-metalbook-image-wapper {
	display: flex;
	@media (max-width: 767.98px) {
		flex-direction: column;
		gap: 24px;
		margin: 0rem !important;
		display: none;
	}
}
// .about-us-swiper {
//     margin: 0rem !important;
//     .swiper{
//         width: 320px;
//          height: 240px;
//     }
// }

.meet-metalb-left-team {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.award-wapper {
		@media (max-width: 767.98px) {
			gap: 1.5rem;
		}
	}
}

//////// end meet metalbook team ///////

//////// start metalbook winner ///////
.metalbook-winner-container {
	display: flex;
	padding: 88px 100px;
	flex-direction: row;
	background: #0d0d12;
	--bs-gutter-y: 1.5rem;

	@media (max-width: 767.98px) {
		padding: 48px 12px;
		--bs-gutter-x: 0rem;
		justify-content: center;
	}

	.winner-card {
		display: flex;
		width: 100%;
		padding: 24px 16px;
		@media (max-width: 767.98px) {
			height: auto;
			padding: 16px;
		}
		flex-direction: column;
		align-items: center;
		gap: 12px;
		border-radius: 24px;
		background: #fff;
		h4 {
			text-align: center;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%;
			/* 28.8px */
			letter-spacing: -0.24px;
			@media (max-width: 767.98px) {
				font-size: 18px;
			}
		}
		p {
			color: #1d1e25;
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			@media (max-width: 767.98px) {
				font-size: 14px;
			}
			/* 21.6px */
		}
	}
}
/////// end metalbook winner /////////

///////// start our investors ////////

.our-investors {
	display: flex;
	padding: 140px 0px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	@media (max-width: 767.98px) {
		padding: 27px 12px;
		gap: 0px;
	}
}

.our-investors-heading {
	display: flex;
	flex-direction: column;
	gap: 40px;
	.heading-wapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 50px;
		@media (max-width: 767.98px) {
			gap: 24px;
			.scroll-tab {
				flex-wrap: wrap;
			}
		}
		.heading {
			text-align: center;
		}
		p {
			text-align: center;
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px;
			/* 145.455% */
			letter-spacing: -0.22px;
			@media (max-width: 767.98px) {
				font-size: 14px;
				letter-spacing: 0px;
				line-height: normal;
				text-align: justify;
			}
		}
	}

	.category {
		@media (max-width: 767.98px) {
			font-size: 0.75rem;
		}
	}
}

.investors-list {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	margin-bottom: 4rem;
	@media (max-width: 767.98px) {
		flex-direction: column;
		align-items: center;
		gap: 30px;
		margin-bottom: 0rem;
	}
}

///////// start our investors ////////

.our-investors {
	display: flex;
	padding: 140px 0px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	@media (max-width: 767.98px) {
		padding: 27px 12px;
		gap: 0px;
	}
}

.our-investors-heading {
	display: flex;
	flex-direction: column;
	gap: 40px;
	.heading-wapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 50px;
		@media (max-width: 767.98px) {
			gap: 24px;
			.scroll-tab {
				flex-wrap: wrap;
			}
		}
		.heading {
			text-align: center;
		}
		p {
			text-align: center;
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px;
			/* 145.455% */
			letter-spacing: -0.22px;
			@media (max-width: 767.98px) {
				font-size: 14px;
				letter-spacing: 0px;
				line-height: normal;
				text-align: justify;
			}
		}
	}

	.category {
		@media (max-width: 767.98px) {
			font-size: 0.75rem;
		}
	}
}

.investors-list {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	@media (max-width: 767.98px) {
		flex-direction: column;
		align-items: center;
		gap: 30px;
	}
}

.investors-price {
	display: flex;
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 1.5rem;
	@media (max-width: 767.98px) {
		--bs-gutter-x: 0.7rem;
		--bs-gutter-y: 0.7rem;
	}
	h2 {
		font-size: 56px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
		letter-spacing: -0.56px;
	}

	p {
		color: #1d1e25;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
		/* 21.6px */
	}
}

.investors-list-wapper {
	padding: 1rem;
	border-radius: 1rem;
	@media (max-width: 767.98px) {
		padding: 0.5rem;
	}
}

.investors-list-card {
	border-radius: 16px;
	box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
	--bs-gutter-x: 0rem !important;
	flex: 1;
	overflow: hidden;
	@media (max-width: 767.98px) {
		gap: 0px;
		border-radius: 16px;
		h2 {
			font-size: 32px;
		}
		p {
			font-size: 14px;
		}
	}
}

///////// end our investors ////////

//////// start explore passion ///////

.explore-passion-container {
	svg {
		width: 100%;
		height: 100%;
	}
}

.explore-passion-wapper {
	background-image: url('../../assets/about-us/Oval-left-top.webp'), url('../../assets/about-us/Oval-right-bottom.webp');
	background-position: left top, right bottom;
	background-repeat: no-repeat;
	padding: 100px 0px;
	display: flex;
	justify-content: center;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		padding: 40px 0px;
	}
}

.explore-passion-content {
	align-items: center;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		--bs-gutter-x: 0rem;
		img:first-child {
			width: 100%;
			height: 100%;
		}
	}
}

.explore-passion-text-content {
	display: flex;
	width: 100%;
	padding: 59px 58px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 40px;
	background: var(--Color-4, #fff);
	box-shadow: 0px 64.8px 48.6px -36px rgba(23, 135, 252, 0.12);
	position: relative;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		width: 100%;
		padding: 15.325px 26.862px;
		align-items: center;
		gap: 10px;
		bottom: 1rem;
	}

	h3 {
		color: #1d1e25;
		font-size: 43.2px;
		font-style: normal;
		font-weight: 700;
		line-height: 51.84px;
		@media (max-width: 767.98px) {
			text-align: center;
			font-size: 20px;
			line-height: 24.009px;
		}
	}
	p {
		color: #666d80;
		font-size: 15.3px;
		font-style: normal;
		font-weight: 500;
		line-height: 27px;
		opacity: 0.7;
		@media (max-width: 767.98px), (max-width: 991.98px) {
			text-align: center;
			font-size: 10px;
			font-style: normal;
			line-height: 12.505px;
		}
	}
	.image-wapper {
		position: absolute;
		bottom: -45px;
		right: -40px;
		@media (max-width: 767.98px), (max-width: 991.98px) {
			bottom: -3rem;
			right: -0.5rem;
			img {
				width: 5rem !important;
			}
		}
	}
	img {
		width: 100px;
		height: 100px;
	}
}

.explore-open-position {
	display: flex;
	padding: 12px 24px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: linear-gradient(90deg, #f6a200 -29.7%, #ea3162 100.3%);
	@media (max-width: 767.98px), (max-width: 991.98px) {
		padding: 5.558px 11.115px;
		justify-content: center;
		align-items: center;
		gap: 3.705px;
	}
	p {
		color: #fff;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		@media (max-width: 767.98px) {
			font-size: 8.336px;
		}
	}
}

/////// end explore passion /////////

/////// start Our Team //////
.our-team-container {
	display: flex;
	padding: 88px 0px;
	flex-direction: column;
	gap: 80px;
	@media (max-width: 767.98px) {
		padding: 27px 12px;
		gap: 24px;
	}
}

.our-team-heading {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

// .our-team-tilte{
//     color: var(--Greyscale-900, #0D0D12);
//     font-size: 20px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 140%;
//     /* 28px */
// }

// .our-team-designation{
//     color: var(--Greyscale-500, #666D80);
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 155%;
//     @media (max-width: 767.98px) {

//     }
//     /* 27.9px */
// }

.our-team-card {
	padding: 1rem;
	img {
		height: 18rem;
	}
	@media (max-width: 767.98px) {
		.card-rounded {
			height: 16rem;
		}
		padding: 0.3rem;
		img {
			height: 10rem;
		}
	}
}
/////// end Our Team //////
