///////////// start banner //////////////

.blogs-container {
	margin-top: $SPACING_20B !important;
	padding-bottom: 6rem;

	@media (max-width: 767.98px) {
		margin-top: 2rem !important;
		padding-bottom: 2rem;

		img {
			width: 100%;
			height: 100%;
		}
	}
}
.banner-heading {
	margin-top: 6rem;
	font-size: 3.5rem !important;
	font-weight: 600;
	line-height: 67px;

	@media (max-width: 767.98px) {
		margin-top: 2rem;
		font-size: 28px !important;
		line-height: 36px;
		letter-spacing: -2%;
	}
}

.banner-sub-heading {
	color: #7e8492;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 1rem;
	@media (max-width: 767.98px) {
		margin: 0px !important;
		margin-top: 1rem;
		font-size: 16px;
		line-height: 24px;
	}
}

.rating {
	margin: 1rem 0rem;
	@media (max-width: 767.98px) {
		margin: 0.5rem 0rem;
	}
	span {
		font-size: 14px;
	}
	svg {
		margin: 5px;
	}
}
.product-details-banner {
	background-color: #e1d3fa;
	position: relative;
}
.product-details-banner-1 {
	position: absolute;
	border-radius: 400px;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) -67.91%, rgba(255, 255, 255, 0.32) 102.41%);
	height: 100%;
	width: 100%;
	transform: translate(-30%, -10%);
}

.product-details-banner-img {
	z-index: 1;
	position: relative;
	width: 100%;
}

.toltip-img {
	width: 8rem;
	height: 8rem;
	margin: 0rem 0.7rem;
	@media (max-width: 767.98px) {
		width: 3.5rem;
		height: 3.5rem;
		margin: 0rem 0.3rem;
	}
}

.buy-now {
	margin-top: 3rem;
	@media (max-width: 767.98px) {
		margin-top: 1rem;
	}
}

.buy-now > div > .card-selected {
	@media (max-width: 767.98px) {
		margin-top: 1rem;
	}
}

///////////// end banner //////////////

//////////// Start Quotation //////////

.product-card-wapper {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.product-card-content {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.product-form-wapper {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.quotation-heading {
	margin-top: 3rem;
	@media (max-width: 767.98px) {
		margin-top: 0rem;
	}
}

.card-rounded {
	border-radius: $SPACING_4B;
	img {
		min-width: 100%;
		min-height: 100%;
		width: 100%;
		object-fit: contain;
		@media (max-width: 767.98px) {
			min-height: auto;
			flex-shrink: unset;
		}
	}
}

.product-step-1 {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.card-category {
	display: flex;
	gap: 5px;
}

.quotation-add-card {
	min-width: auto !important;
	min-height: auto !important;
	width: auto !important;
}

.quotation-container {
	padding: 0rem 1rem 12rem;
	margin-top: 1rem;
	@media (max-width: 767.98px) {
		flex-direction: column;
		padding: 1rem 0.5rem;
		margin-top: 0rem;
	}
}

.quotation-card-container {
	padding: 0rem 7rem 0rem 1rem !important;
	@media (max-width: 767.98px) {
		padding: 0px !important;
	}
}

.quotation-card {
	padding: 0.5rem;
	@media (max-width: 767.98px) {
		padding: 0.3rem 0.3rem;
	}
}

.quotation-card-heading {
	font-weight: 600;
	font-size: 24px;
	line-height: 22px;
}

.quotation-card-content {
	p {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
}

/////////////////

.quotation-card-hover {
	position: relative;
	background-color: #e1d3fa;
	min-height: 210px;
	border-radius: 1rem;

	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	img {
		&:hover {
			opacity: 0.4;
		}
	}

	button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		opacity: 0;
	}

	@media (max-width: 767.98px) {
		min-height: 120px;
	}
}

.quotation-card-hover:hover {
	opacity: 0.9;

	button {
		opacity: 1;
		z-index: 2;
	}
}
/// //////////

.progress-bar-heading {
	p {
		font-size: 10px;
	}
	div {
		width: fit-content;
	}
}

.progress-bar-continer {
	margin: 16px 0px;
	padding: 0px 2.2rem;
	@media (max-width: 767.98px) {
		padding: 0px 2rem;
	}
}

///////// End Quotation ////////////

.RSPBprogressBar {
	height: 2px !important;
}

.RSPBprogressBar .RSPBprogression {
	background: #3ec082 !important;
}

////////// start boottom menu ///////
// .bottom-menu {
// 	display: none;
// 	padding: 12px 18px;
// 	gap: 10px;
// 	background: #202225;
// 	@media (max-width: 767.98px) {
// 		display: block;
// 	}
// 	position: fixed;
// 	width: 100%;
// 	bottom: 0;
// 	z-index: 99;
// 	div {
// 		p {
// 			color: $text-white;
// 			font-weight: 600;
// 			font-size: 16px;
// 			line-height: 24px;
// 			display: flex;
// 			align-items: center;
// 		}
// 	}
// }
////////// end boottom menu ///////

///////////// product-details ///////////

.carousel-item {
	img {
		width: 100% !important;
		height: 50vh;
	}
}

.carousel-control-prev {
	align-items: flex-end;
	bottom: 32px;
	width: 13%;

	@media (max-width: 767.98px) {
		width: 18%;
	}
}

.carousel-control-next {
	align-items: flex-end;
	bottom: 32px;
	width: 13%;

	@media (max-width: 767.98px) {
		width: 18%;
	}
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	height: 35px;
	width: 35px;
	outline: black;
	background-size: 100%, 100%;
	border-radius: 50%;
	border: 1px solid black;
	background-image: none;
	background-color: #fff;
}

.carousel-control-next-icon:after {
	content: '>';
	font-size: 40px;
	color: #000;
	position: relative;
	bottom: 12px;
}

.carousel-control-prev-icon:after {
	content: '<';
	font-size: 40px;
	color: #000;
	position: relative;
	bottom: 12px;
}

.blog-details-banner-heading {
	font-size: 38px;
	font-weight: 600;
	line-height: 53px;
	letter-spacing: -0.2px;
	@media (max-width: 767.98px) {
		margin-top: 1rem;
		font-size: 24px;
		line-height: 33px;
	}
}

.blog-price {
	font-size: 28px;
	font-weight: 600;
	line-height: 39px;
	letter-spacing: -0.2px;
	@media (max-width: 767.98px) {
		font-size: 20px;
	}
}

.product-description {
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	color: #818b9c;
	// margin: 1rem 0rem;

	@media (max-width: 767.98px) {
		margin: 0.5rem 0rem;
		font-size: 14px;
	}
}

.product-item {
	font-size: 16px;
	font-weight: 500;
	line-height: 22.5px;
	letter-spacing: 0.2px;
	color: #0b0f0e;
}

.add-to-card-text {
	font-size: 18px;
	font-weight: 600;
	line-height: 25.2px;
	letter-spacing: -0.2px;
}

.product-attributes {
	padding: 16px !important;
	border-radius: 16px !important;
	margin: 0px 10px;
	@media (max-width: 767.98px) {
		padding: 8px 18px !important;
		align-self: center;
		border-radius: 8px !important;
	}

	p {
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		@media (max-width: 767.98px) {
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
		}
	}
}

/////////////// Start Attributes Details //////////////

.attribute-details-container {
	display: flex;
	padding: 80px 0rem;
	flex-direction: column;
	justify-content: center;
	// align-items: center;
	gap: 56px;

	hr {
		height: 1px;
		background: #e4e9ee;
		width: 100%;
	}
	.specification-wapper {
		@media (max-width: 767.98px) {
			flex-direction: column;
		}
	}
}

.attribute-wapper {
	display: flex !important;
	flex-direction: column;
	gap: 32px;
}

.attributes-title {
	font-weight: 600;
	font-size: 28px;
	line-height: 39.2px;
	color: #0b0f0e;
}

.calculate-weight > .attributes-title {
	margin-top: 3rem;
	@media (max-width: 767.98px) {
		margin-top: 1rem;
	}
}

.attributes-description {
	font-weight: 400;
	font-size: 16px;
	line-height: 25.2px;
	margin: 1rem 0rem;
	color: #818b9c;
}

.attributes-sub-heading {
	font-weight: 600;
	font-size: 18px;
	line-height: 25.2px;
	margin: 1rem 0rem;
	color: #0b0f0e;
}

.attributes-description-value {
	font-weight: 500;
	font-size: 16px;
	line-height: 25.2px;
	color: #0b0f0e;
}

.attributes-description-space {
	img {
		margin-right: 5px;
	}
	svg {
		margin-right: 5px;
	}
	margin: 10px 0px;
}

/////////////// End Attributes Details //////////////

////////////// Start Related Product /////////////////

.related-product-container {
	display: flex;
	padding: 0px 0px 96px 0px;
	flex-direction: column;
	gap: 24px;
}
.related-product > .card-img-top {
	width: 100%;
	height: auto;
}

.card-img-top {
	width: 100%;
	height: 12rem;
	object-fit: cover;
	@media (max-width: 767.98px) {
		// height: 8rem;
	}
}

.related-product-heading {
	font-weight: 600;
	font-size: 18px;
	line-height: 25.2px;
	color: #0b0f0e;
}

.related-product-price {
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
}

////////////// End Related Product /////////////////

.sticky-cart {
	width: fit-content;
	visibility: hidden;
	@media (max-width: 767.98px) {
		span {
			border-radius: 20px;
			color: white;
			background: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
			padding: 8px 8px;
			// aspect-ratio: 1/1;
		}
		visibility: visible;
		position: fixed;
		z-index: 2;
		right: 1%;
		bottom: 2%;
		svg {
			// background: linear-gradient(0deg, $background-yellow -76.29%, $background-red 89.33%);
			// background-clip: text;
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
		}
		animation: zoom-in-zoom-out 1s ease infinite;
	}
	.cart-numbers {
		display: flex;
		position: absolute;
		z-index: 1;
		top: -12px;
		font-size: 10px;
		left: 43px;
		height: 17px;
		width: 17px;
		padding: 0;
		border-radius: 15px;
		justify-content: center;
		align-items: center;
	}
}
