.header-wapper {
	padding: 0.5rem 1rem;
}

.header-logo {
	@media (max-width: 767.98px) {
		svg:first-child {
			width: 42px;
			height: 40px;
		}
	}
}

.logo-text {
	font-size: 1.2rem;
	// font-weight: 600;
	margin: 0rem;
	span {
		font-weight: 300;
	}
	b {
		font-weight: 600;
	}
}

.navbar-toggler-icon {
	@media (max-width: 767.98px) {
		width: 1em;
	}
}

.dropdown-menu {
	margin-top: 0.5rem !important;
	left: -6rem !important;
	padding: 0;
	border: 0px;

	@media (max-width: 767.98px) {
		position: relative !important;
		left: 0px !important;
		top: 0px !important;
	}
}
.offcanvas-header {
	@media (max-width: 767.98px) {
		border: 1px solid #e9edf5;
	}
}

.offcanvas-body {
	@media (max-width: 767.98px) {
		display: flex;
		flex-direction: column;

		.nav-link-button > * {
			margin-top: 10px;
		}
	}
}
.offcanvas-body-nav {
	justify-content: flex-end;
	align-items: center;

	@media (max-width: 767.98px) {
		display: block;
		flex: 1;
		justify-content: flex-start;
		align-items: normal;
		padding: 0px !important;
		gap: 0px !important;
		overflow: scroll;
		.shadow {
			box-shadow: none !important;
		}

		> * {
			border-bottom: 1px solid #e9edf5;
			min-height: 50px;
		}

		> a {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		> .dropdown {
			padding-top: 5px;
		}

		#offcanvasNavbarDropdown-expand-md {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex: 1;
		}
	}
}

.header-container {
	width: 39rem;
	flex-direction: row;
	border-radius: 20px;
	@media (max-width: 767.98px) {
		width: auto;
		flex-direction: column;
	}
}

.row-header-left {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2rem 2rem 1rem !important;

	svg {
		font-size: 18px;
		margin-right: 10px;
	}
	.dropdown-item {
		border-radius: 5px;
		padding-left: 5px;
	}
	@media (max-width: 767.98px) {
		// align-items: center;
		padding: 1rem !important;
	}
}

.row-header-right {
	margin: 1rem !important;
	padding: 1rem;
	border-radius: 20px;
	flex: 1;
	background: #f7f9f9;
	@media (max-width: 767.98px) {
		padding: 1rem !important;
		margin: 0rem 1rem 1rem !important;
	}

	.align-items-center {
		flex: 1;
		div {
			flex: 1;
		}
		img {
			margin-right: 1rem;
		}
		img:first-child {
			width: 60px;
			height: 60px;
			margin-left: 0.5rem;
			padding: 10px;
		}
	}
	p {
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
		display: flex;
		align-items: center;
		img {
			width: 12px;
			height: 16px;
			margin-left: 8px;
		}
	}
	span {
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
	}

	.dropdown-item {
		background-color: #fff;
		border-radius: 20px;
		margin-bottom: 10px;
	}
}

.dropdown-item {
	padding: 0.5rem 0rem;
}

.header-title {
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 1rem;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: none;
}

.sign-button {
	border: none !important;
	@media (max-width: 767.98px) {
		display: flex;
		.btn {
			flex: 1;
			border-radius: 30px;
		}
	}
}

.home-mobile-view {
	display: none;
	@media (max-width: 767.98px) {
		display: block;
	}
}
