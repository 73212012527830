$background-main : #003767;
$background-green : #007f2e;
$background-green2 : #3ec082;
$background-yellow : #f6a200;
$background-red: #ea3162;
$background-lightgrey: #f9f9f9;
$background-black: #000;
$background-black1: #202225;
$background-greenlight: #0ab4bb;
$background-greenlight2: #017f84;
$background-greenlight3: #019298;

$text-white: #ffffff;
$text-white2: #f1f1f5;
$text-black : #0000;
$text-black2 : #101010;
$text-black3 : #0d0d12;
$text-black4 : #1d1e25;
$text-black5: #0D0D12;
$text-black6: #313336;
$text-grey : #757b8a;
$text-grey2 : #677489;
$text-grey3 : #44444f;
$text-grey4 : #292d32;
$text-grey5 : #b2b1b4;
$text-grey6 : #eee;
$text-grey7 : #7e8492;
$text-grey8 : #ededed;
$text-grey9 : #eaf4f0;
$text-grey10 : #787F84;
$text-grey11 : #676767;
$text-grey12 : #dee2e6;