///////////// start Banner //////////////
.blog-container {
	img {
		width: 100%;
		height: 100%;
		border-radius: 20px;
	}
	@media (max-width: 767.98px) {
		img {
			width: 100%;
			height: 100%;
		}
	}
}
.blog-banner-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.blog-heading {
	color: var(--Greyscale-900, $text-black5);
	font-size: $XXX_LARGE;
	font-style: normal;
	font-weight: $BOLD_600;
	line-height: $LINE_HEIGHT_MEDIUM;
	@media (max-width: 767.98px) {
		line-height: 120%;
		margin-top: 24px;
		font-size: 1.6rem;
		padding-left: 2px;
	}
}

.blog-finance {
	color: #44444f;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	padding: 5px 10px;
	border-radius: 4px;
	border: 1px solid #dcdcdc;
}

.blog-date {
	color: #666d80;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	margin: 0px 5px;
}

.read-more {
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-right: 5px;
	text-decoration: underline;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

//////////// End Banner ////////////////

//////////// Start blog list ////////////////

.blog-list-container {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 66px 0rem;
	@media (max-width: 767.98px) {
		padding: 0.5rem;
	}
	.row {
		@media (max-width: 767.98px) {
			margin-right: 0;
			margin-left: 0;
			.heading {
				line-height: 32px;
			}
		}
	}
}

.blog-title {
	color: #757b8a;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

.blog-card-wapper {
	--bs-gutter-y: 40px;
	--bs-gutter-x: 32px;
}

/////////// End blog list //////////////////

/////////// start pagination ////////////

.pagination-container {
	display: flex;
	list-style-type: none;
	justify-content: center;
	@media (max-width: 767.98px) {
		padding-left: 0px;
		column-gap: 3px;
	}
	.pagination-item {
		display: flex;
		width: 48px;
		height: 48px;
		padding: 12px 10px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		border: 1px solid #d4d4d4;
		margin: 0px 5px;
		@media (max-width: 767.98px) {
			margin: 0px;
			width: 28px;
			height: 28px;
			padding: 12px 10px;
			border-radius: 10px;
		}

		&.dots:hover {
			background-color: transparent;
			cursor: default;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
			cursor: pointer;
		}

		&.selected {
			display: flex;
			width: 48px;
			height: 48px;
			padding: 12px 13px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			border-radius: 12px;
			border: 1px solid var(--Primary-grad, #f6a200);
			background: var(--Primary-grad, linear-gradient(0deg, #f6a200 -76.29%, #ea3162 89.33%));
			@media (max-width: 767.98px) {
				margin: 0px;
				width: 28px;
				height: 28px;
				padding: 12px 10px;
				border-radius: 10px;
			}
			p {
				color: #fff;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 123.6%;
				@media (max-width: 767.98px) {
					font-size: 14px;
				}
			}
		}

		.arrow {
			&.left {
				color: #ea3162;
				margin-right: 40px;
				@media (max-width: 767.98px) {
					margin-right: 10px;
				}
			}
			&.right {
				color: #ea3162;
				margin-left: 40px;
				@media (max-width: 767.98px) {
					margin-left: 10px;
				}
			}
		}

		&.disabled {
			pointer-events: none;
			div {
				color: #bcbcbc !important;
			}
			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}

	li:first-child,
	:last-child {
		color: var(--Disable, #bcbcbc);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		border: none;
		&:hover {
			background-color: unset;
			cursor: pointer;
		}
		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
}

///////// end pagination ////////////

/////////// Start Blog Loader ////////////
.blog-loder {
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
////////// End Blog Loader //////////////

///////////// Blog Details ////////////

.blog-details-wapper {
	display: flex;
	flex-direction: column;
	padding: 3rem 10rem;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		padding: 0rem;
	}
}

.blog-details-banner {
	img {
		width: 100%;
		height: 100%;
	}
	display: flex;
	flex-direction: column;
	gap: 20px;
	@media (max-width: 767.98px) {
		display: flex;
		padding: 16px;
		gap: 15px;
	}
	.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.blog-details-heading {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		padding: 0rem;
		gap: 1rem;
	}
	p {
		color: var(--Greyscale-900, #0d0d12);
		text-align: center;
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: 125%;
		@media (max-width: 767.98px) {
			font-size: 32px;
			text-align: left;
		}
	}
	hr {
		background-color: #c21625;
		color: red;
		height: 2px;
		width: 100%;
	}
}

.blog-author {
	display: flex;
	justify-content: space-between;
	padding: 32px 0rem;
	color: var(--Greyscale-500, #666d80);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	/* 25.6px */
	@media (max-width: 767.98px) {
		flex-direction: row;
		padding: 16px 24px;
		font-size: 13px;
		gap: 18px;
	}
	button {
		margin-left: 19px;
	}
}

.blog-details-content-container {
	display: flex;
	align-items: flex-start;
	gap: 80px;
	@media (max-width: 767.98px) {
		padding: 24px 24px;
		flex-direction: column;
		gap: 40px;
	}
}

.blog-details-content-wapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	flex: 1 0 0;
	width: 100%;
	p {
		color: var(--Greyscale-500, #666d80);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 155%;
		/* 27.9px */
		margin-bottom: 16px;
	}
	h2 {
		color: var(--Greyscale-900, #0d0d12);
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 135%;
		/* 43.2px */
	}
}

.blog-related-content-container {
	display: flex;
	flex-direction: column;
	padding: 64px 0px;
	gap: 48px;
	@media (max-width: 767.98px) {
		padding: 48px 16px;
		flex-direction: column;
		gap: 40px;
	}
	h2 {
		color: var(--Greyscale-900, #0d0d12);
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 135%;
		/* 43.2px */
	}
}

.blog-faq-accordian {
	width: 100%;
	margin: 1rem 0rem 1.5rem 0rem !important;
	@media (max-width: 767.98px) {
		margin-bottom: 0.5rem !important;
	}
}

.faq-heading {
	font-size: 32px;
	font-weight: 600;
}

.blog-faq-header {
	font-size: 18px;
	font-weight: 400;
	line-height: 160%;
	color: #000000;
	@media (max-width: 767.98px) {
		font-size: 14px;
		line-height: 27px;
	}
}

.blog-faq-answer {
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	color: #04040499;
	padding-top: 0rem;
}
