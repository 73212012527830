.policy-container {
	display: flex;
	flex-direction: column;
}

.policy-header {
	padding: 64px 140px 24px 140px;
	gap: 16px;
	@media (max-width: 767.98px) {
		padding: 48px 16px 24px 16px;
		gap: 32px;
	}
	.h2 {
		font-size: 48px;
		font-weight: 600;
		line-height: 125%;
		@media (max-width: 767.98px) {
			font-size: 32px;
		}
	}
	.paragraph {
		@media (max-width: 767.98px) {
			font-size: 18px;
		}
	}
	.policy-p {
		color: #787f84;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 0px;
		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
}

.policy-content {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0px 140px 101px 140px;
	@media (max-width: 767.98px) {
		padding: 0px 16px 24px 16px;
	}

	.subheading {
		font-size: 24px;
		font-weight: 600;
		line-height: 125%;
		padding-top: 16px;
		color: var(--Greyscale-900, #0d0d12);
		@media (max-width: 767.98px) {
			// padding: 48px 16px 24px 16px;

			font-size: 28px;
			line-height: 135%;
		}
	}

	.paragraph {
		@media (max-width: 767.98px) {
			font-size: 18px;
		}
	}
}

.listed-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 10px;
}
.checked-circle {
	position: relative;
	background: none;
	border-radius: 50%;
	border: #787f84 1px solid;
	width: 20px;
	height: 20px;
	min-width: 18px;
	min-height: 18px;

	@media (max-width: 767.98px) {
		width: 22px;
		height: 22px;
		min-width: 20px;
		min-height: 20px;
		margin-top: 1%;
	}

	div {
		position: absolute;
		transform: rotate(45deg) translate(-50%, -50%);
		left: 25%;
		top: 50%;
		height: 0.59em;
		width: 0.3em;
		border-bottom: 1px solid #787f84;
		border-right: 1px solid #787f84;
	}
}

.terms-content {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0px 140px 101px 140px;
	@media (max-width: 767.98px) {
		padding: 0px 16px 24px 16px;
	}

	.subheading {
		font-size: 20px;
		font-weight: 600;
		line-height: 125%;
		padding-top: 16px;
		color: var(--Greyscale-900, #0d0d12);
		@media (max-width: 767.98px) {
			// padding: 48px 16px 24px 16px;

			font-size: 16px;
			line-height: 135%;
		}
	}

	.paragraph {
		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
	.term-bullet {
		font-weight: 500;
		@media (max-width: 767.98px) {
			font-size: 12px;
		}
	}
}
