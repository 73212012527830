//////////////// start services banner //////////////

.services-banner-container {
	background-image: url('../../assets/services/right-bottom.webp'), url('../../assets/services/left-top.webp');
	background-position: right top, left top;
	background-repeat: no-repeat;
	// height: 100vh;
	@media (max-width: 767.98px) {
		background-image: none;
	}
}

.services-banner-top {
	display: flex;
	align-items: center;
	margin-top: 5rem;
	@media (max-width: 767.98px), (max-width: 991.98px) {
		margin-top: 0rem;
		flex-direction: column-reverse;
	}
}

.services-banner-left {
	margin-left: 5rem;
	display: flex;
	flex-direction: column;
	gap: 15px;
	@media (max-width: 767.98px) {
		margin: 0rem 1rem;
	}

	.heading {
		color: #202225;
		font-size: 60px;
		font-style: normal;
		font-weight: 600;
		line-height: 125%;
		/* 85px */
		letter-spacing: -0.68px;
		@media (max-width: 767.98px) {
			font-size: 32px;
			letter-spacing: -2%;
			line-height: 41px;
		}
	}

	.heading-2 {
		font-size: 18px;
		font-weight: 400;
		color: #7e8492;
		text-align: justify;
		@media (max-width: 767.98px) {
			font-size: 14px;
		}
	}
}

.services-banner-right {
	// margin-top: 3rem;
	// margin-right: 4rem;
	@media (max-width: 767.98px) {
		margin-top: 0rem;
		margin-right: 0rem;
	}

	img {
		object-fit: contain;
		width: 100%;
		@media (max-width: 767.98px) {
			height: 100%;
		}
	}
}

.services-banner-action {
	// margin-top: 1.5rem;
	margin-right: 5rem;
	@media (max-width: 767.98px) {
		flex-direction: column;
		margin-right: 0rem;
	}
}

//////////////// end services banner //////////////

////////////// Start support ///////////////

.support-container {
	margin-top: 7rem;
	margin-bottom: 10rem;
	.support-content-video-player {
		height: 30rem;
	}
	@media (max-width: 767.98px) {
		margin-top: 2rem;
		margin-bottom: 2rem;
		.support-content-video-player {
			height: auto;
		}
	}
}

.support-video-container {
	position: relative;
}

.support-card-row {
	display: flex;
	flex-wrap: nowrap;
	position: absolute;
	bottom: -6rem;
	gap: 1rem;

	margin: -0.7rem;
	@media (max-width: 767.98px) {
		flex-wrap: wrap;
		gap: 1rem;
		bottom: 0rem;
		position: relative;
		justify-content: space-evenly;
		margin: 0rem;
	}
}

.youtube-action {
	display: none;
	@media (max-width: 767.98px) {
		display: flex;
	}
}

.support-card-col {
	display: flex;
	align-items: baseline;
	overflow: hidden;
	padding: 1rem;
	@media (max-width: 767.98px) {
		flex-direction: column;
		padding: 0.5rem;
	}
	.support-card-img {
		display: flex;
		align-items: center;
		gap: 10px;

		@media (max-width: 767.98px) {
			flex-direction: column;
			align-items: center;
		}
	}
}

.support-card-title {
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
	@media (max-width: 767.98px) {
		font-size: 12px;
		text-align: center;
		line-height: normal;
	}
}

.support-card-description {
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	margin: 0.8rem 0rem;
	@media (max-width: 767.98px) {
		font-size: 10px;
		margin: 0.2rem 0rem;
		line-height: 15px;
	}
}

//////////// End support //////////////////

/////////// start total revenue ///////////

.total-revenue-container {
	padding: 2rem 0rem;
	@media (max-width: 767.98px) {
		padding: 2rem 1rem;
	}
}

.total-revenue-heading {
	font-weight: 600;
	font-size: 40px;
	line-height: 56px;
	letter-spacing: -2%;
	padding-bottom: 2rem;
	@media (max-width: 767.98px) {
		font-size: 32px;
		line-height: 41px;
	}
}

.total-revenue-content {
	display: flex;
	margin-top: 3rem;
	@media (max-width: 767.98px) {
		margin-top: 0rem;
		flex-direction: column-reverse;
	}
}

.total-revenue-content-accordion {
	margin: 1rem 0rem 3rem 0rem !important;
	@media (max-width: 767.98px) {
		margin-bottom: 0.5rem !important;
	}
}

.accordion-item:not(:first-of-type) {
	border-top: outset !important;
}

.accordion-button:focus {
	box-shadow: unset !important;
}

.accordion-button:not(.collapsed) {
	background-color: unset;
}

.accordion-button:not(.collapsed) {
	box-shadow: none;
}

.total-revenue-graph {
	display: flex;
	justify-content: center;
	img {
		width: 100%;
		height: 35rem;
		object-fit: cover;
		@media (max-width: 767.98px) {
			width: 100%;
			height: 100%;
			display: none;
		}
	}
}
.accordion-button {
	border-radius: 2rem !important;
}

.total-accordion-Header {
	font-size: 20px;
	font-weight: 400;
	line-height: 160%;
	color: #000000;
	@media (max-width: 767.98px) {
		font-size: 14px;
		line-height: 27px;
	}
}

.total-accordion-body {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: #04040499;
	padding-top: 0rem;
}

/////////// end total revenue ////////////

///////// start speak-to-us /////////////

.speak-to-us-container {
	background: #292d32;
	padding: 5rem 0rem;
	margin: 6rem 0rem;
	@media (max-width: 767.98px) {
		padding: 2rem 0rem;
		margin: 3rem 0rem;
	}
	background-image: url('../../assets/services/speak-to-us-right.webp'), url('../../assets/services/speak-to-us-left.webp');
	background-position: right top, left bottom;
	background-repeat: no-repeat;
	@media (max-width: 767.98px) {
		background-image: url('../../assets/services/speak-to-us-mobile.webp');
	}
}

.speak-to-us-wapper {
	display: flex;
	@media (max-width: 767.98px) {
		flex-direction: column;
		gap: 1rem;
	}
}

.speak-to-wapper {
	margin-top: 0;
	@media (max-width: 767.98px) {
		margin-top: 0rem;
	}
}

.seack-to-us-heading {
	font-size: 48px;
	font-weight: 600;
	line-height: 56px;
	letter-spacing: -2%;
	color: #f8fafb;
	@media (max-width: 767.98px) {
		font-size: 32px;
		line-height: 43px;
	}
}

.seack-to-us-description {
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
	color: #e7e8e9;
	margin: 1.5rem 0rem;
	width: 80%;
	text-align: justify;
	@media (max-width: 767.98px) {
		font-size: 15px;
		line-height: 22px;
		width: 100%;
	}
}

.speak-to-us-form {
	background-color: #ffffff;
	padding: 2rem;
	border-radius: 1.5rem;
	@media (max-width: 767.98px) {
		padding: 1rem;
	}
}

.speak-to-us-form-heading {
	font-size: 28px;
	font-weight: 500;
	line-height: 43px;
}

.speck-form-label {
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	color: #0d0d12;
}

.speak-input {
	padding: 11px;
}

//////// end speak-to-us ///////////////

//////// start Frequently Asked Questions ///////

.frequently-asked-questions {
	margin-bottom: 10rem;
}
.frequently-asked-heading {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	h2 {
		font-size: 48px;
		font-weight: 600;
		line-height: 56px;
		letter-spacing: -2%;
		@media (max-width: 767.98px) {
			font-size: 28px;
			line-height: 36px;
		}
	}
	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -1%;
		color: #787f84;
		@media (max-width: 767.98px) {
			font-size: 18px;
		}
	}
}

.frequently-asked-header {
	font-weight: 600 !important;
	color: #212529 !important;
}

.accordion-services-wapper {
	padding: 0rem 12rem;
	@media (max-width: 767.98px) {
		padding: 0rem;
	}
	.card {
		border-radius: unset !important;
	}
}

.accordion-heading {
	display: flex;
	justify-content: space-between;
	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -1%;
		color: #787f84;
	}
}

.frequently-asked-card-header {
	background-color: transparent;
}

.frequently-asked-body {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -1%;
}
/////// end Frequently Asked Questions /////////

/////// form ////////

.privacy-policy-wapper {
	font-size: $EXTRA_SMALL;
}
.commodity-form-items {
	font-size: $SMALL;
}
//////
